import React, { useContext, useState } from "react"
import _noop from "lodash/noop"
import { isEmailVerified } from "../utils/firebase.utils"
import { createUser } from "../api/common"
import AuthContext from "../contexts/AuthContext"
import { firebaseAuth } from "../../firebaseConfig"
import Loader from "./Loader"

const VerifyEmail = ({ email }) => {
  const [verifyiingEmail, setVerfyingEmail] = useState(false)
  const { getAndSetuser } = useContext(AuthContext)
  const onVerify = async () => {
    setVerfyingEmail(true)
    const isUserEmailVerified = await isEmailVerified()
    console.log({ isUserEmailVerified })
    if (isUserEmailVerified) {
      await getAndSetuser(firebaseAuth.currentUser)

      if (window?.history?.length > 0) {
        window?.history?.back() ?? _noop()
      } else {
        window.location.href = "/"
      }
      // navigate to home or previous screen
    } else {
      alert("Email not verified. Please click the link sent to your email")
    }
    // if (isUserEmailVerified) {
    //   const userDetailsObj = {
    //     displayName,
    //     phoneNumber,
    //     email: Email,
    //     companyName,
    //     profession,
    //     userCity,
    //     referral,
    //   }
    //   console.log({ userDetails, userDetailsObj, metadata })
    //   // await createUser(
    //   //   getCreateUserPayload(userDetails, userDetailsObj, metadata)
    //   // )
    // setUser(userDetails)
    // console.log({ window: window.history })
    // navigate("/")
    // if (window?.history?.length > 0) {
    //   window?.history?.back() ?? {}()
    // } else {
    //   navigate("/")
    // }
    // } else {
    //   alert("Email not verified. Please click the link sent to your email")
    // }

    setVerfyingEmail(false)
  }

  return (
    <div className="row">
      <div className="col-md-4"></div>
      <div className="col-md-4 d-flex flex-column align-items-center">
        <p>
          We have sent a verification link to your email{" "}
          <span className="bold">{email}</span>. Please check.
        </p>
        {verifyiingEmail ? (
          <div
            className="d-flex justify-content-center"
            style={{ width: "100%" }}
          >
            <Loader />
          </div>
        ) : (
          <button
            onClick={onVerify}
            style={{
              backgroundColor: "#2798B5",
              alignItems: "center",
              border: "none",
              borderRadius: 4,
              color: "white",
              padding: "4px 16px",
              alignSelf: "center",
              marginBottom: 64,
            }}
          >
            Verify Email
          </button>
        )}
      </div>
    </div>
  )
}

export default VerifyEmail
